import React from 'react';

export default function Header() {
  return (
    <div className="header">
      <span className="name">zain salman</span>
      <div className="links">
        <a target="_blank" href="https://github.com/zainjamin" rel="noreferrer">
          //github
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/in/zain-salman/"
          rel="noreferrer"
        >
          //linkedin
        </a>
        <a target="_blank" href="resume.pdf" rel="noreferrer">
          //resume
        </a>
      </div>
    </div>
  );
}
