import { useMediaQuery } from './mediaQuery';

const containerStyle = (isSmallScreen) => {
  return {
    paddingTop: '1rem',
    paddingLeft: '8px',
    borderLeft: '1px solid #b1aebb',
  };
};

export default function CourseNotes(props) {
  const isSmallScreen = useMediaQuery('(max-width: 628px)');
  return (
    <div style={containerStyle(isSmallScreen)}>
      <table>
        <tr>
          <th>Course</th>
          <th>Term Taken</th>
          <th>Instructor</th>
          <th>My Notes</th>
        </tr>
        <tr>
          <td>PMATH 347 - Groups and Rings</td>
          <td>Spring 2024</td>
          <td>Stephen New</td>
          <td>
            <a href="pmath347_notes.pdf">PMATH 347 Course Notes</a>
          </td>
        </tr>
      </table>
    </div>
  );
}
