/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import { getAge } from './utils/dateUtils';
import { useMediaQuery } from './mediaQuery';

const containerStyle = (isRowBased) => {
  return {
    paddingTop: '1rem',
    paddingLeft: '8px',
    borderLeft: '1px solid #b1aebb',
    display: 'flex',
    flexDirection: isRowBased ? 'column' : 'row',
    justifyContent: 'space-between',
  };
};

const commented = (isRowBased) => {
  return {
    color: '#b1aebb',
    fontStyle: 'italic',
    width: isRowBased ? 'auto' : '30vw',
    textAlign: isRowBased ? 'left' : 'right',
  };
};

const commentList = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '16px',
};

export default function AboutMe(props) {
  const isRowBased = useMediaQuery('(max-width: 628px)');
  const age = getAge('2004-09-09');
  return (
    <div style={containerStyle(isRowBased)}>
      <p>
        &#123;
        <br />
        &nbsp; age: {age},
        <br />
        &nbsp; pronouns: <span style={{ color: '#abba7c' }}>"he/him"</span>,
        <br />
        &nbsp; city: <span style={{ color: '#abba7c' }}>"Waterloo"</span>,<br />
        &nbsp; country: <span style={{ color: '#abba7c' }}>"CA"</span>,<br />
        &#125;
      </p>
      <div style={commentList}>
        <p style={commented(isRowBased)}>
          /* i was born and raised in the greater toronto area. */
        </p>
        <p style={commented(isRowBased)}>
          /* i am an aspiring software developer. */
        </p>
        <p style={commented(isRowBased)}>
          /* i love pokemon! my favourite is phanpy. */
        </p>
      </div>
    </div>
  );
}
