import React from 'react';
import './App.css';
import Header from './Header';
import Intro from './Intro';
import ZainMethod from './ZainMethod';
import AboutMe from './AboutMe';
import Experience from './Experience';
import Projects from './Projects';
import CourseNotes from './CourseNotes';

function App() {
  return (
    <div className="content">
      <Header />
      <div className="body">
        <Intro />
        <ZainMethod method="aboutMe" content={<AboutMe />} />
        <ZainMethod method="getExperience" content={<Experience />} />
        <ZainMethod method="getProjects" content={<Projects />} />
        <ZainMethod method="courseNotes" content={<CourseNotes />} />
      </div>
    </div>
  );
}

export default App;
