import React, { useState } from 'react';
import { useMediaQuery } from './mediaQuery';
import HTMLIcon from './images/icons/html.png';
import SASSIcon from './images/icons/sass.png';
import JSIcon from './images/icons/javascript.png';
import TSIcon from './images/icons/typescript.png';
import ReactIcon from './images/icons/react.png';
import TailwindIcon from './images/icons/tailwind-css.svg';
import RubyIcon from './images/icons/ruby.png';
import RailsIcon from './images/icons/rails.png';
import UnityIcon from './images/icons/unity.png';
import FlutterIcon from './images/icons/flutter.png';
import Badge from './Badge';

const containerStyle = (isSmallScreen) => {
  return {
    paddingTop: '1rem',
    paddingLeft: '8px',
    borderLeft: '1px solid #b1aebb',
    display: 'flex',
    flexDirection: isSmallScreen ? 'column' : 'row',
    gap: isSmallScreen ? '8px' : '32px',
  };
};

const jobListStyle = (isSmallScreen) => {
  return {
    listStyle: 'none',
    display: 'flex',
    flexDirection: isSmallScreen ? 'row' : 'column',
    overflow: isSmallScreen ? 'auto' : '',
  };
};

const jobsStyle = (isSmallScreen, isActiveJob) => {
  return {
    whiteSpace: 'nowrap',
    backgroundColor: isActiveJob ? '#444054' : '',
  };
};

const datesStyle = {
  fontSize: '0.75rem',
  color: '#b1aebb',
};

const badgeListStyle = {
  marginTop: '8px',
  display: 'flex',
  gap: '8px',
  flexWrap: 'wrap',
};

const listStyle = {
  marginTop: '8px',
  listStyle: 'square inside',
  gap: '8px',
};

export default function Experience(props) {
  const isSmallScreen = useMediaQuery('(max-width: 628px)');
  const [activeJob, setActiveJob] = useState('TD Lab');
  const toggleJob = (e) => {
    setActiveJob(e.currentTarget.textContent);
  };
  return (
    <div style={containerStyle(isSmallScreen)}>
      <ul style={jobListStyle(isSmallScreen)}>
        <li
          style={jobsStyle(isSmallScreen, activeJob === 'TD Lab')}
          onClick={toggleJob}
          className="job"
        >
          TD Lab
        </li>
        <li
          style={jobsStyle(
            isSmallScreen,
            activeJob === 'Red Thread Innovations',
          )}
          onClick={toggleJob}
          className="job"
        >
          Red Thread Innovations
        </li>
        <li
          style={jobsStyle(isSmallScreen, activeJob === 'Inkblot Therapy')}
          onClick={toggleJob}
          className="job"
        >
          Inkblot Therapy
        </li>
      </ul>
      {activeJob === 'Inkblot Therapy' && (
        <div>
          <span>Software Developer </span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://inkblottherapy.com"
            id="inkblot"
          >
            @ Inkblot Therapy
          </a>
          <div style={datesStyle}>June 2022 - September 2022</div>
          <div style={badgeListStyle}>
            <Badge icon={HTMLIcon} text="HTML" color="orange" />
            <Badge icon={SASSIcon} text="SCSS" color="hotpink" />
            <Badge icon={TailwindIcon} text="Tailwind" color="skyblue" />
            <Badge icon={JSIcon} text="JavaScript" color="yellow" />
            <Badge icon={TSIcon} text="TypeScript" color="royalblue" />
            <Badge icon={ReactIcon} text="React" color="lightblue" />
            <Badge icon={RubyIcon} text="Ruby" color="red" />
            <Badge icon={RailsIcon} text="Ruby on Rails" color="red" />
          </div>
          <ul style={listStyle}>
            <li>
              Developed a custom React component library using Tailwind CSS and
              Typescript to increase code reusability.
            </li>
            <li>
              Implemented templates using Action Mailer on a back-end Ruby on
              Rails server to improve automated emails.
            </li>
          </ul>
        </div>
      )}
      {activeJob === 'Red Thread Innovations' && (
        <div>
          <span>Junior Software Engineer </span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.redthreadinnovations.com/"
            id="redthread"
          >
            @ Red Thread Innovations
          </a>
          <div style={datesStyle}>May 2023 - September 2023</div>
          <div style={badgeListStyle}>
            <Badge icon={HTMLIcon} text="HTML" color="orange" />
            <Badge icon={SASSIcon} text="SCSS" color="hotpink" />
            <Badge icon={TSIcon} text="TypeScript" color="royalblue" />
            <Badge icon={ReactIcon} text="React" color="lightblue" />
          </div>
          <ul style={listStyle}>
            <li>
              Created a platform to design web applications, decreasing
              developement time for future projects by 150%.
            </li>
            <li>
              Implemented Atomic Design using React components exported from
              Figma.
            </li>
          </ul>
        </div>
      )}
      {activeJob === 'TD Lab' && (
        <div>
          <span>Software Developer Co-Op </span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.td.com"
            id="tdlab"
          >
            @ TD Lab
          </a>
          <div style={datesStyle}>January 2024 - April 2024</div>
          <div style={badgeListStyle}>
            <Badge icon={ReactIcon} text="React" color="lightblue" />
            <Badge icon={TailwindIcon} text="Tailwind" color="skyblue" />
            <Badge icon={UnityIcon} text="Unity" color="#7393B3" />
            <Badge icon={FlutterIcon} text="Flutter" color="aqua" />
          </div>
          <ul style={listStyle}>
            <li>
              Designed a custom Unity shader in HLSL to simulate colour
              blindness in VR by converting to the LMS colour space and applying
              a matrix transformation.
            </li>
            <li>
              Implemented designs for web applications by creating modular
              components in React, following Atomic Design.
            </li>
            <li>
              Created rapid prototypes for cross-platform mobile applications
              using the Flutter framework in Dart.
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
