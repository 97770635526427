import React, { useState } from 'react';
import Anime from 'react-anime';

export default function ZainMethod(props) {
  const [show, setShow] = useState(true);
  return (
    <div>
      <div
        tabIndex={0}
        onClick={(e) => {
          setShow(!show);
          e.currentTarget.classList.toggle('pressed');
        }}
        className="action"
      >
        <span style={{ color: '#ba7cab' }}>zain</span>.{props.method}()
      </div>
      {show && (
        <Anime
          opacity={[0, 1]}
          easing="linear"
          translateY={[0, '1rem']}
          duration={700}
        >
          {props.content}
        </Anime>
      )}
    </div>
  );
}
